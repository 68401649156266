import {
    Group,
    Header as MantineHeader,
    useMantineTheme,
    MediaQuery,
    Burger,
    Stack,
    Image,
    Text,
    Flex
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import useBreadcrumbs, { BreadcrumbsRoute } from 'use-react-router-breadcrumbs';

import { BreadcrumbContext } from 'shared/components/baseLayout/BreadcrumbContext';
import doka from './assets/doka.png';
import { Breadcrumbs } from './Breadcrumbs';
import { default as styles } from './Header.module.scss';
import { UserImage } from './UserImage';

type Props = {
    isNavBarOpened: boolean;
    onBurgerMenuClick: () => void;
    isNavBarVisible: boolean;
};

export const Header = ({ isNavBarOpened, onBurgerMenuClick: handleBurgerMenuClicked, isNavBarVisible }: Props) => {
    const theme = useMantineTheme();
    const isMobile = useMediaQuery('(max-width: 768px)');
    const baseBreadcrumbRoute: BreadcrumbsRoute = {
        path: '/',
        breadcrumb: 'Home'
    };
    const breadcrumbRoutes = useContext(BreadcrumbContext);
    const breadcrumbs = useBreadcrumbs([baseBreadcrumbRoute, ...breadcrumbRoutes]);

    const showBreadcrumbs = breadcrumbs.length > 1;

    return (
        <MantineHeader height={showBreadcrumbs ? 80 : 50} px="md">
            <Stack spacing="xs">
                <Group
                    className={styles.header}
                    position="apart"
                    sx={{
                        background: theme.colors.gray[9]
                    }}
                >
                    <Group position="left">
                        {isNavBarVisible && (
                            <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
                                <Flex align="center">
                                    <Burger
                                        opened={isNavBarOpened}
                                        onClick={handleBurgerMenuClicked}
                                        size="sm"
                                        color={theme.colors.gray[6]}
                                        mr={-5}
                                    />
                                </Flex>
                            </MediaQuery>
                        )}
                        <Link to="/" state={{ preventRedirect: true }} style={{ textDecoration: 'none' }}>
                            <Group>
                                <Image src={doka} height={30} width={30} />
                                <Text color={theme.white} size="xl">
                                    DFDS Management
                                </Text>
                            </Group>
                        </Link>
                    </Group>
                    <UserImage />
                </Group>
                {showBreadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} maxElements={isMobile ? 2 : undefined} />}
            </Stack>
        </MantineHeader>
    );
};
