import { Flex, Stack, Title, Button } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { useGetApiFeedbackFileId } from 'api/feedback/feedback';
import { LoadingAnimation } from 'shared/components';
import { BaseLayout } from 'shared/components/baseLayout';

export function DownloadFile() {
    const { fileId = '' } = useParams();
    const { t } = useTranslation();

    const { data, isFetching } = useGetApiFeedbackFileId(fileId);

    return (
        <BaseLayout showNavBar={false}>
            {isFetching || !data ? (
                <LoadingAnimation />
            ) : (
                <Stack align="center">
                    <Title my="xl" order={3}>
                        {t('fileIsReady')}
                    </Title>
                    <Flex>File Length: {data?.fileLength} Bytes</Flex>
                    <Flex>User: {data?.userId} </Flex>
                    <Flex>FileType: {data?.fileExtension} </Flex>
                    <Flex gap="xl" justify="center" wrap="wrap">
                        <Button variant="filled" component="a" href={data.sas as string}>
                            {t('Download')}
                        </Button>
                    </Flex>
                </Stack>
            )}
        </BaseLayout>
    );
}
