import { createStyles, MantineTheme } from '@mantine/core';

export const useStyles = createStyles((theme: MantineTheme) => {
    const warningColor = theme.colors.orange[4];

    return {
        warning: {
            '.mantine-Input-input': {
                borderColor: warningColor
            },
            '.mantine-Input-input, .mantine-MultiSelect-searchInput': {
                'color': warningColor,
                '&::placeholder': {
                    opacity: 0.5,
                    color: warningColor
                }
            },
            'color': warningColor
        },
        warningLabel: {
            color: warningColor,
            fontSize: '12px',
            lineHeight: '1.2'
        }
    };
});
