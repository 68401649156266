import { Breadcrumbs as MantineBreadcrumbs, Anchor, useMantineTheme, Text } from '@mantine/core';
import { IconChevronsRight } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { BreadcrumbData } from 'use-react-router-breadcrumbs';

type Props = {
    breadcrumbs: BreadcrumbData[];
    maxElements?: number;
};

export const Breadcrumbs = ({ breadcrumbs, maxElements }: Props) => {
    const mantineTheme = useMantineTheme();

    const elements = !maxElements ? breadcrumbs : breadcrumbs.slice((maxElements + 1) * -1);

    return (
        <MantineBreadcrumbs
            styles={{
                root: {
                    fontSize: '14px',
                    marginTop: '-6px'
                },
                breadcrumb: {
                    color: mantineTheme.black
                },
                separator: {
                    color: mantineTheme.black,
                    fontSize: '14px'
                }
            }}
            separator={<IconChevronsRight size="20" />}
        >
            {elements.map((item, index, array) => {
                return index !== array.length - 1 ? (
                    <Anchor
                        component={Link}
                        to={item.key}
                        state={index <= 0 ? { preventRedirect: true } : undefined}
                        key={item.key}
                    >
                        {item.breadcrumb}
                    </Anchor>
                ) : (
                    <Text component="span" key={item.key}>
                        {item.breadcrumb}
                    </Text>
                );
            })}
        </MantineBreadcrumbs>
    );
};
