import { DefaultProps, UnstyledButton, useMantineTheme } from '@mantine/core';
import { CSSProperties, forwardRef } from 'react';
import { default as InitialsAvatar } from 'react-avatar';

import { default as styles } from './Avatar.module.scss';

interface AvatarProps extends DefaultProps {
    src?: string | undefined;
    name?: string | undefined;
    size?: number;
}

export const Avatar = forwardRef<HTMLDivElement, AvatarProps>(
    ({ src, name, size = 35, style, ...others }: AvatarProps, ref) => {
        const baseStyles: CSSProperties = {
            border: '1px solid rgba(0, 0, 0, 0)',
            boxSizing: 'border-box',
            overflow: 'hidden'
        };
        const theme = useMantineTheme();

        return (
            <div ref={ref} {...others}>
                <UnstyledButton className={styles.avatar} style={{ ...baseStyles, ...style }}>
                    <InitialsAvatar
                        round={true}
                        name={name}
                        alt={''}
                        title={''}
                        src={src}
                        maxInitials={3}
                        size={size?.toString()}
                        color={
                            theme.fn.variant({
                                variant: 'filled'
                            }).background
                        }
                    />
                </UnstyledButton>
            </div>
        );
    }
);

Avatar.displayName = 'Avatar';
