import { IconUsersGroup, IconUser } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import { BreadcrumbComponentProps, BreadcrumbsRoute } from 'use-react-router-breadcrumbs';

import { BreadcrumbProvider, NavLinksProvider } from '../../shared/components/baseLayout';
import { ClientInstaller } from './components/ClientInstaller';

export const DownloadCenterBaseRoute = 'download_center';
export const DownloadCenterEnterpriseBaseRoute = 'download_center_enterprise';

export const DownloadCenterRoutes = () => {
    const { t } = useTranslation();

    const breadcrumbs: BreadcrumbsRoute[] = [
        {
            path: `${DownloadCenterBaseRoute}/:fileId`,
            breadcrumb: DownloadCenterBreadcrumb
        }
    ];

    const navLinks = [
        { link: DownloadCenterBaseRoute, label: t('clientInstaller'), icon: IconUser },
        { link: DownloadCenterEnterpriseBaseRoute, label: t('enterpriseInstaller'), icon: IconUsersGroup }
    ];

    return (
        <BreadcrumbProvider breadcrumbs={breadcrumbs}>
            <NavLinksProvider navLinks={navLinks}>
                <Routes>
                    <Route path="/" element={<ClientInstaller />} />
                    {/* <Route path="/" element={<EnterpriseInstaller />} /> */}
                </Routes>
            </NavLinksProvider>
        </BreadcrumbProvider>
    );
};

export const DownloadCenterBreadcrumb = ({ _ }: BreadcrumbComponentProps<'fileId'>) => {
    return <>{'...'}</>;
};
