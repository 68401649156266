import { IconFiles } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import { BreadcrumbComponentProps, BreadcrumbsRoute } from 'use-react-router-breadcrumbs';

import { BreadcrumbProvider, NavLinksProvider } from '../../shared/components/baseLayout';
import { DownloadFile } from './components/DownloadFile';
import { Files } from './components/Files';

export const FileBaseRoute = 'files';

export const FileRoutes = () => {
    const { t } = useTranslation();

    const breadcrumbs: BreadcrumbsRoute[] = [
        {
            path: `${FileBaseRoute}/:fileId`,
            breadcrumb: FileBreadcrumb
        }
    ];

    const navLinks = [{ link: FileBaseRoute, label: t('myFeedbackFiles'), icon: IconFiles }];

    return (
        <BreadcrumbProvider breadcrumbs={breadcrumbs}>
            <NavLinksProvider navLinks={navLinks}>
                <Routes>
                    <Route path="/" element={<Files />} />
                    <Route path="/:fileId" element={<DownloadFile />} />
                </Routes>
            </NavLinksProvider>
        </BreadcrumbProvider>
    );
};

export const FileBreadcrumb = ({ _ }: BreadcrumbComponentProps<'fileId'>) => {
    return <>{'...'}</>;
};
