import { IconSettings } from '@tabler/icons-react';
import { Route, Routes } from 'react-router-dom';
import { BreadcrumbComponentProps, BreadcrumbsRoute } from 'use-react-router-breadcrumbs';

import { BreadcrumbProvider, NavLinksProvider } from '../../shared/components/baseLayout';
import { SoftwareConfig } from './components/softwareconfig';
export const SoftwareConfigBaseRoute = 'software_configuration';

export const SoftwareConfigRoutes = () => {
    const breadcrumbs: BreadcrumbsRoute[] = [
        {
            path: `${SoftwareConfigBaseRoute}/:fileId`,
            breadcrumb: SoftwareConfigBreadcrumb
        }
    ];

    const navLinks = [
        { link: 'test', label: 'test 1', icon: IconSettings },
        { link: 'test', label: 'test 2', icon: IconSettings },
        { link: 'test', label: 'test 3', icon: IconSettings },
        { link: 'test', label: 'test 4', icon: IconSettings }
    ];

    return (
        <BreadcrumbProvider breadcrumbs={breadcrumbs}>
            <NavLinksProvider navLinks={navLinks}>
                <Routes>
                    <Route path="/" element={<SoftwareConfig />} />
                </Routes>
            </NavLinksProvider>
        </BreadcrumbProvider>
    );
};

export const SoftwareConfigBreadcrumb = ({ _ }: BreadcrumbComponentProps<'fileId'>) => {
    return <>{'...'}</>;
};
