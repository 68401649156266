import { Title, Text, Skeleton, Flex, Group, ActionIcon, Tooltip } from '@mantine/core';
import { IconX, IconFilter } from '@tabler/icons-react';
import React, { ReactNode, createContext, Children, useContext, PropsWithChildren, isValidElement } from 'react';
import { useTranslation } from 'react-i18next';

import { CardBase, CardDimensions } from './CardBase';
import { CreateItemModal } from './CreateItemModal';
import { DefaultPlaceholder } from './placeholder';

export type AddDialogProps = {
    onCancel: () => void;
    Title?: ReactNode;
};

const CardDimensionContext = createContext<CardDimensions | null>(null);

const CardDimensionContextProvider = ({ width, height, children }: CardDimensions & PropsWithChildren) => {
    return <CardDimensionContext.Provider value={{ height, width }}>{children}</CardDimensionContext.Provider>;
};

const AddItemButton = ({ onClick }: { onClick: () => void }) => {
    const cardDimensionContext = useContext(CardDimensionContext);

    return (
        <CardBase
            height={cardDimensionContext?.height}
            width={cardDimensionContext?.width}
            onClick={() => onClick()}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer'
            }}
        >
            <Title order={1} color="dimmed">
                +
            </Title>
        </CardBase>
    );
};

type Props = {
    isLoading: boolean;
    header?: string | undefined;
    cardHeight?: number | undefined;
    cardWidth?: number | undefined;
    AddDialogType?: (props: AddDialogProps) => ReactNode;
    addDialogTitle?: ReactNode;
    children: ReactNode;
    onClearFilters?: () => Promise<void> | void;
    areFiltersSet?: boolean;
    placeholder?: React.ReactElement;
};

type FilterProps = {
    children: ReactNode;
};

export const Filter = ({ children }: FilterProps) => {
    return children;
};

Filter.DisplayName = 'FlexListFilter';

export const FlexList = ({
    children,
    header,
    cardHeight,
    cardWidth,
    AddDialogType,
    addDialogTitle,
    isLoading = false,
    onClearFilters: handleClearFilters,
    areFiltersSet = false,
    placeholder: noData = <DefaultPlaceholder />
}: Props) => {
    const { t } = useTranslation();

    const addDialogTitleNode = typeof addDialogTitle === 'string' ? <Text>{addDialogTitle}</Text> : addDialogTitle;
    const childArray = Children.toArray(children);

    const filters = childArray.filter(
        (child) =>
            isValidElement(child) &&
            typeof child.type === 'function' &&
            'DisplayName' in child.type &&
            child.type.DisplayName === Filter.DisplayName
    );
    const otherItems = childArray.filter((child) => !filters?.some((filter) => filter === child));

    if (filters.length !== 0 && !handleClearFilters) {
        throw new Error('onClearFilters has to be implemented when filters are set.');
    }

    return (
        <>
            <Title order={2}>{header}</Title>
            {filters && filters.length !== 0 && (
                <Group mt="sm">
                    <IconFilter />
                    {filters}
                    <Tooltip label={t('clearFilter')}>
                        <ActionIcon onClick={handleClearFilters} disabled={!areFiltersSet}>
                            <IconX />
                        </ActionIcon>
                    </Tooltip>
                </Group>
            )}
            <Skeleton visible={isLoading}>
                {otherItems.length <= 0 ? (
                    noData
                ) : (
                    <Flex mt="xl" mih={50} gap="md" justify="left" align="flex-start" direction="row" wrap="wrap">
                        {otherItems}
                        {AddDialogType && (
                            <>
                                <CardDimensionContextProvider height={cardHeight} width={cardWidth}>
                                    <CreateItemModal
                                        title={addDialogTitleNode}
                                        AddDialogType={AddDialogType}
                                        AddButton={AddItemButton}
                                    />
                                </CardDimensionContextProvider>
                            </>
                        )}
                    </Flex>
                )}
            </Skeleton>
        </>
    );
};

FlexList.Filter = Filter;
