import { Stack, Title } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { BaseLayout } from 'shared/components/baseLayout';

export function Files() {
    const { t } = useTranslation();
    return (
        <BaseLayout showNavBar={false}>
            <Stack align="center">
                <Title my="xl" order={3}>
                    {t('underconstruction')}
                </Title>
            </Stack>
        </BaseLayout>
    );
}
