import { createStyles, getStylesRef } from '@mantine/core';

export const useStyles = createStyles((theme, _params) => {
    const icon = getStylesRef('icon');
    return {
        link: {
            ...theme.fn.focusStyles(),
            'display': 'flex',
            'alignItems': 'center',
            'textDecoration': 'none',
            'fontSize': theme.fontSizes.sm,
            'color': theme.black,
            'padding': `${theme.spacing.xs} ${theme.spacing.sm}`,
            'borderRadius': theme.radius.sm,
            'fontWeight': 500,

            '&:hover': {
                backgroundColor: theme.colors.gray[1],
                color: theme.fn.variant({
                    variant: 'light',
                    color: theme.primaryColor
                }).color,
                [`& .${icon}`]: {
                    color: theme.fn.variant({
                        variant: 'light',
                        color: theme.primaryColor
                    }).color
                }
            }
        },

        linkIcon: {
            ref: icon,
            color: theme.black,
            marginRight: theme.spacing.sm
        },

        linkActive: {
            '&, &:hover': {
                backgroundColor: theme.fn.variant({
                    variant: 'filled'
                }).background,
                color: theme.white,
                [`& .${icon}`]: {
                    color: theme.white
                }
            }
        }
    };
});
