import { useOidc } from '@axa-fr/react-oidc';
import { OidcUserInfo } from '@axa-fr/vanilla-oidc';
import { Button, Stack, Text, Divider, Center } from '@mantine/core';
import { IconExternalLink, IconLogout } from '@tabler/icons-react';

import { Avatar } from 'shared/components/avatar';
import { useStyles } from './UserImageDropDownContent.styles';

type UserImageDropDownContentProps = {
    userProfile?: OidcUserInfo | undefined;
    userImage?: string | undefined;
};

export const UserImageDropDownContent = ({ userProfile, userImage }: UserImageDropDownContentProps) => {
    const loginServerUrl = import.meta.env.VITE_APP_OIDC_AUTHORITY;
    const { logout } = useOidc();
    const { classes } = useStyles();

    return (
        <>
            <Center style={{ marginTop: '5px' }}>
                <Avatar size={100} src={userImage} name={userProfile?.name} />
            </Center>

            <Stack py="xs" align="center" spacing={0} className={classes.userStack}>
                <Text weight="bold" align="center">
                    {userProfile?.name}
                </Text>
                <Text>{userProfile?.email}</Text>
            </Stack>
            <Divider style={{ width: '100%' }} />
            <Stack py="xs" align="center" spacing={0} className={classes.buttonStack}>
                <Button
                    component="a"
                    target="_blank"
                    href={`${loginServerUrl}/User`}
                    variant="subtle"
                    fullWidth
                    leftIcon={<IconExternalLink size={18} />}
                    color="dark"
                    className={classes.button}
                >
                    Manage Profile
                </Button>
                <Button
                    onClick={() => logout()}
                    variant="subtle"
                    fullWidth
                    leftIcon={<IconLogout size={18} />}
                    color="dark"
                    className={classes.button}
                >
                    Logout
                </Button>
            </Stack>

            {/* <Divider style={{ width: '100%' }} />
                <Text px={5} py={2} m={0} size="xs" underline italic component={Link} to="#">
                    {t('terms')}
                </Text> */}
        </>
    );
};
