import { TablerIconsProps } from '@tabler/icons-react';
import { createContext, ReactElement } from 'react';

export interface NavLinksConfig {
    link: string;
    label?: string | undefined;
    icon: (props: TablerIconsProps) => ReactElement;
}

export const NavLinksContext = createContext<NavLinksConfig[]>([]);
