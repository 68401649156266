/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * DFDS API
 * OpenAPI spec version: v0.7
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
    UseQueryOptions,
    UseMutationOptions,
    QueryFunction,
    MutationFunction,
    UseQueryResult,
    QueryKey
} from '@tanstack/react-query';
import type { SasForFeedbackUploadDto, PostApiFeedbackSendFeedbackBody, FeedbackFileDto } from '.././models';
import { customMutator } from '.././customMutator';
import type { ErrorType } from '.././customMutator';

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

export const postApiFeedbackRequestUpload = (options?: SecondParameter<typeof customMutator>) => {
    return customMutator<SasForFeedbackUploadDto>({ url: `/api/Feedback/RequestUpload`, method: 'post' }, options);
};

export const getPostApiFeedbackRequestUploadMutationOptions = <
    TError = ErrorType<unknown>,
    TVariables = void,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postApiFeedbackRequestUpload>>,
        TError,
        TVariables,
        TContext
    >;
    request?: SecondParameter<typeof customMutator>;
}): UseMutationOptions<Awaited<ReturnType<typeof postApiFeedbackRequestUpload>>, TError, TVariables, TContext> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiFeedbackRequestUpload>>, TVariables> = () => {
        return postApiFeedbackRequestUpload(requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type PostApiFeedbackRequestUploadMutationResult = NonNullable<
    Awaited<ReturnType<typeof postApiFeedbackRequestUpload>>
>;

export type PostApiFeedbackRequestUploadMutationError = ErrorType<unknown>;

export const usePostApiFeedbackRequestUpload = <
    TError = ErrorType<unknown>,
    TVariables = void,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postApiFeedbackRequestUpload>>,
        TError,
        TVariables,
        TContext
    >;
    request?: SecondParameter<typeof customMutator>;
}) => {
    const mutationOptions = getPostApiFeedbackRequestUploadMutationOptions(options);

    return useMutation(mutationOptions);
};
export const postApiFeedbackSendFeedback = (
    postApiFeedbackSendFeedbackBody: PostApiFeedbackSendFeedbackBody,
    options?: SecondParameter<typeof customMutator>
) => {
    const formData = new FormData();
    if (postApiFeedbackSendFeedbackBody.FeedbackText !== undefined) {
        formData.append('FeedbackText', postApiFeedbackSendFeedbackBody.FeedbackText);
    }
    if (postApiFeedbackSendFeedbackBody.BlobName !== undefined) {
        formData.append('BlobName', postApiFeedbackSendFeedbackBody.BlobName);
    }
    if (postApiFeedbackSendFeedbackBody.FileLength !== undefined) {
        formData.append('FileLength', postApiFeedbackSendFeedbackBody.FileLength.toString());
    }
    if (postApiFeedbackSendFeedbackBody.Md5Hash !== undefined) {
        formData.append('Md5Hash', postApiFeedbackSendFeedbackBody.Md5Hash);
    }
    if (postApiFeedbackSendFeedbackBody.ApplicationName !== undefined) {
        formData.append('ApplicationName', postApiFeedbackSendFeedbackBody.ApplicationName);
    }

    return customMutator<boolean>(
        {
            url: `/api/Feedback/SendFeedback`,
            method: 'post',
            headers: { 'Content-Type': 'multipart/form-data' },
            data: formData
        },
        options
    );
};

export const getPostApiFeedbackSendFeedbackMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postApiFeedbackSendFeedback>>,
        TError,
        { data: PostApiFeedbackSendFeedbackBody },
        TContext
    >;
    request?: SecondParameter<typeof customMutator>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof postApiFeedbackSendFeedback>>,
    TError,
    { data: PostApiFeedbackSendFeedbackBody },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postApiFeedbackSendFeedback>>,
        { data: PostApiFeedbackSendFeedbackBody }
    > = (props) => {
        const { data } = props ?? {};

        return postApiFeedbackSendFeedback(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type PostApiFeedbackSendFeedbackMutationResult = NonNullable<
    Awaited<ReturnType<typeof postApiFeedbackSendFeedback>>
>;
export type PostApiFeedbackSendFeedbackMutationBody = PostApiFeedbackSendFeedbackBody;
export type PostApiFeedbackSendFeedbackMutationError = ErrorType<unknown>;

export const usePostApiFeedbackSendFeedback = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postApiFeedbackSendFeedback>>,
        TError,
        { data: PostApiFeedbackSendFeedbackBody },
        TContext
    >;
    request?: SecondParameter<typeof customMutator>;
}) => {
    const mutationOptions = getPostApiFeedbackSendFeedbackMutationOptions(options);

    return useMutation(mutationOptions);
};
export const getApiFeedbackFileId = (
    id: string,
    options?: SecondParameter<typeof customMutator>,
    signal?: AbortSignal
) => {
    return customMutator<FeedbackFileDto>({ url: `/api/Feedback/File/${id}`, method: 'get', signal }, options);
};

export const getGetApiFeedbackFileIdQueryKey = (id: string) => [`/api/Feedback/File/${id}`] as const;

export const getGetApiFeedbackFileIdQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiFeedbackFileId>>,
    TError = ErrorType<unknown>
>(
    id: string,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getApiFeedbackFileId>>, TError, TData>;
        request?: SecondParameter<typeof customMutator>;
    }
): UseQueryOptions<Awaited<ReturnType<typeof getApiFeedbackFileId>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetApiFeedbackFileIdQueryKey(id);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiFeedbackFileId>>> = ({ signal }) =>
        getApiFeedbackFileId(id, requestOptions, signal);

    return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetApiFeedbackFileIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiFeedbackFileId>>>;
export type GetApiFeedbackFileIdQueryError = ErrorType<unknown>;

export const useGetApiFeedbackFileId = <
    TData = Awaited<ReturnType<typeof getApiFeedbackFileId>>,
    TError = ErrorType<unknown>
>(
    id: string,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getApiFeedbackFileId>>, TError, TData>;
        request?: SecondParameter<typeof customMutator>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getGetApiFeedbackFileIdQueryOptions(id, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

export const getApiFeedbackHasFileReadPermission = (
    options?: SecondParameter<typeof customMutator>,
    signal?: AbortSignal
) => {
    return customMutator<boolean>({ url: `/api/Feedback/HasFileReadPermission`, method: 'get', signal }, options);
};

export const getGetApiFeedbackHasFileReadPermissionQueryKey = () => [`/api/Feedback/HasFileReadPermission`] as const;

export const getGetApiFeedbackHasFileReadPermissionQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiFeedbackHasFileReadPermission>>,
    TError = ErrorType<unknown>
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getApiFeedbackHasFileReadPermission>>, TError, TData>;
    request?: SecondParameter<typeof customMutator>;
}): UseQueryOptions<Awaited<ReturnType<typeof getApiFeedbackHasFileReadPermission>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetApiFeedbackHasFileReadPermissionQueryKey();

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiFeedbackHasFileReadPermission>>> = ({ signal }) =>
        getApiFeedbackHasFileReadPermission(requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetApiFeedbackHasFileReadPermissionQueryResult = NonNullable<
    Awaited<ReturnType<typeof getApiFeedbackHasFileReadPermission>>
>;
export type GetApiFeedbackHasFileReadPermissionQueryError = ErrorType<unknown>;

export const useGetApiFeedbackHasFileReadPermission = <
    TData = Awaited<ReturnType<typeof getApiFeedbackHasFileReadPermission>>,
    TError = ErrorType<unknown>
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getApiFeedbackHasFileReadPermission>>, TError, TData>;
    request?: SecondParameter<typeof customMutator>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getGetApiFeedbackHasFileReadPermissionQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};
