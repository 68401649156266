import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import { mockApi } from 'api/mockApi';
import { configurationIdentityServer } from 'app/configurationIdentityServer';
import { DefaultErrorBoundary } from 'shared/components';
import { AuthProvider } from 'shared/components/auth';
import { applicationInsightsReactPlugin } from 'utils/applicationInsights';
import { AppInsightsContextProvider } from 'utils/ApplicationInsightsContextProvider';
import { AppRoutes } from './AppRoutes';
import { ThemeOverrides } from './ThemeOverrides';
import './App.css';
import '../i18n';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: { retry: false, refetchOnWindowFocus: false, refetchOnReconnect: false, refetchOnMount: false }
    }
});

export const App = () => {
    const { t } = useTranslation();

    mockApi();

    return (
        <BrowserRouter>
            <MantineProvider theme={ThemeOverrides} withGlobalStyles withNormalizeCSS>
                <Notifications />
                <ModalsProvider labels={{ confirm: t('yes'), cancel: t('no') }} modalProps={{ centered: true }}>
                    <AuthProvider configuration={configurationIdentityServer} autoLogin={true}>
                        <AppInsightsContextProvider>
                            <QueryClientProvider client={queryClient}>
                                <DefaultErrorBoundary reactPlugin={applicationInsightsReactPlugin}>
                                    <AppRoutes />
                                </DefaultErrorBoundary>
                            </QueryClientProvider>
                        </AppInsightsContextProvider>
                    </AuthProvider>
                </ModalsProvider>
            </MantineProvider>
        </BrowserRouter>
    );
};
