import { AppShell } from '@mantine/core';
import { ReactNode, useState } from 'react';

import { Header } from './Header';
import { NavBar } from './NavBar';

type BaseLayoutProps = {
    showNavBar?: boolean;
    children: ReactNode;
};

export const BaseLayout = ({ children, showNavBar = true }: BaseLayoutProps) => {
    const [isMobileNavbarOpened, setIsMobileNavbarOpened] = useState(false);

    const handleBurgerMenuClick = () => {
        setIsMobileNavbarOpened((opened) => !opened);
    };

    const handleNavLinkClicked = () => {
        setIsMobileNavbarOpened(false);
    };

    return (
        <AppShell
            padding="md"
            navbarOffsetBreakpoint="sm"
            navbar={
                showNavBar ? (
                    <NavBar opened={isMobileNavbarOpened} onNavLinkClicked={handleNavLinkClicked} />
                ) : undefined
            }
            header={
                <Header
                    isNavBarOpened={isMobileNavbarOpened}
                    onBurgerMenuClick={handleBurgerMenuClick}
                    isNavBarVisible={showNavBar}
                />
            }
            styles={(theme) => ({
                main: {
                    backgroundColor: theme.colors.gray[0]
                }
            })}
        >
            {children}
        </AppShell>
    );
};
