import { Navbar } from '@mantine/core';
import { useContext } from 'react';
import { NavLink } from 'react-router-dom';

import { NavLinksConfig, NavLinksContext } from 'shared/components/baseLayout/NavLinksContext';
import { useStyles } from './NavBar.styles';

type Props = {
    opened: boolean;
    onNavLinkClicked: (navLink: NavLinksConfig) => void;
};

export const NavBar = ({ opened, onNavLinkClicked }: Props) => {
    const { classes, cx } = useStyles();
    const navLinks = useContext(NavLinksContext);

    const links = navLinks.map((item) => (
        <NavLink
            className={({ isActive }) => {
                return cx(classes.link, {
                    [classes.linkActive]: isActive
                });
            }}
            end={item.link === ''}
            to={item.link}
            key={item.label}
            onClick={() => onNavLinkClicked(item)}
        >
            <item.icon className={classes.linkIcon} stroke={1.5} />
            <span>{item.label}</span>
        </NavLink>
    ));

    return (
        <Navbar width={{ sm: 300 }} hiddenBreakpoint="sm" hidden={!opened} p="md">
            <Navbar.Section grow>{links}</Navbar.Section>
        </Navbar>
    );
};
