import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from 'history';

const applicationInsightsReactPlugin = new ReactPlugin();

const browserHistory = createBrowserHistory({});

const applicationInsights = new ApplicationInsights({
    config: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        connectionString: import.meta.env.VITE_APP_APPLICATION_INSIGHTS_CONNECTIONSTRING,
        extensions: [applicationInsightsReactPlugin],
        extensionConfig: {
            [applicationInsightsReactPlugin.identifier]: { history: browserHistory }
        },
        enableAutoRouteTracking: true,
        disableAjaxTracking: false,
        maxBatchInterval: 0,
        disableFetchTracking: false,
        autoTrackPageVisitTime: true,
        enableCorsCorrelation: true,
        samplingPercentage: 100,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        enableUnhandledPromiseRejectionTracking: true
    }
});

if (import.meta.env.VITE_APP_APPLICATION_INSIGHTS_CONNECTIONSTRING) {
    applicationInsights.loadAppInsights();
    applicationInsights.trackPageView();

    const telemetryInitializer = (envelope: ITelemetryItem) => {
        envelope.tags = envelope.tags ?? [];
        envelope.tags['ai.cloud.role'] = 'DFDS Feedback Management';
        envelope.tags['ai.cloud.roleInstance'] = 'DFDS Feedback Management';
    };

    applicationInsights.addTelemetryInitializer(telemetryInitializer);
}

export { applicationInsightsReactPlugin, applicationInsights };
