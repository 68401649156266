import { MantineThemeOverride } from '@mantine/core';

export const ThemeOverrides: MantineThemeOverride = {
    colors: {
        brand: [
            '#DEE3E4',
            '#C7D1D3',
            '#B0C1C4',
            '#99B4B9',
            '#82ABB1',
            '#6AA4AD',
            '#51A0AD',
            '#518A93',
            '#4F787F',
            '#4B696E'
        ]
    },
    components: {
        Modal: {
            styles: () => ({
                title: { fontWeight: 700, fontSize: '1.625rem', lineHeight: 1.35 }
            })
        },
        Switch: {
            styles: () => ({
                body: {
                    justifyContent: 'space-between'
                },
                track: {
                    cursor: 'pointer'
                }
            })
        }
    },
    primaryColor: 'brand',
    primaryShade: 6
};
