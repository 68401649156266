import { Switch as ExternalSwitch, SwitchProps as ExternalSwitchProps } from '@mantine/core';
import { forwardRef, RefAttributes } from 'react';

import { IMantineFormControl, IWarningProps } from './ExtendedInputControls';
import { InputLabel, InputLabelProps } from './InputLabel';

export type SwitchProps = IMantineFormControl &
    IWarningProps &
    InputLabelProps &
    Omit<ExternalSwitchProps, 'form'> &
    RefAttributes<HTMLInputElement>;

export const Switch = forwardRef<HTMLInputElement, SwitchProps>(
    ({ form, propertyPath: propertyName, label, iconDescription, optional, disabled, ...others }: SwitchProps, ref) => {
        const props = form.getInputProps(propertyName, { type: 'checkbox' });

        return (
            <ExternalSwitch
                onLabel="ON"
                offLabel="OFF"
                size="md"
                disabled={disabled}
                {...props}
                ref={ref}
                {...others}
                label={<InputLabel label={label} optional={optional} iconDescription={iconDescription} />}
            />
        );
    }
);

Switch.displayName = 'Switch';
