import { withOidcSecure } from '@axa-fr/react-oidc';
import { Route, Routes } from 'react-router-dom';

import { DownloadCenterRoutes, DownloadCenterBaseRoute } from 'modules/downloadcenter/Routes';
import { FileRoutes, FileBaseRoute } from 'modules/files/Routes';
import { SoftwareConfigRoutes, SoftwareConfigBaseRoute } from 'modules/softwareconfig/Routes';
import { Home } from './Home';
import { NotFound } from './NotFound';
import { Redirect } from './Redirect';
import { Unauthorized } from './Unauthorized';

export const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/" Component={withOidcSecure(Home)} />
            <Route path="/unauthorized" Component={Unauthorized} />
            <Route path="/redirect" Component={Redirect} />
            <Route path="*" Component={NotFound} />
            <Route path={`/${FileBaseRoute}/*`} Component={withOidcSecure(FileRoutes)} />
            <Route path={`/${SoftwareConfigBaseRoute}/*`} Component={withOidcSecure(SoftwareConfigRoutes)} />
            <Route path={`/${DownloadCenterBaseRoute}/*`} Component={withOidcSecure(DownloadCenterRoutes)} />
        </Routes>
    );
};
