/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable camelcase */
import { OidcConfiguration, TokenRenewMode } from '@axa-fr/react-oidc';

export const configurationIdentityServer: OidcConfiguration = {
    client_id: import.meta.env.VITE_APP_OIDC_CLIENT_ID!,
    redirect_uri: window.location.origin + '/authentication/callback',
    silent_redirect_uri: window.location.origin + '/authentication/silent-callback', // Optional activate silent-signin that use cookies between OIDC server and client javascript to restore the session
    authority_time_cache_wellknowurl_in_second: 60 * 60,
    refresh_time_before_tokens_expiration_in_second: 40,
    scope: 'openid profile email roles dfds.doka.com/feedback',
    authority: import.meta.env.VITE_APP_OIDC_AUTHORITY!,
    service_worker_relative_url: '/OidcServiceWorker.js',
    service_worker_convert_all_requests_to_cors: true,
    service_worker_only: true,
    token_renew_mode: TokenRenewMode.access_token_or_id_token_invalid
};
