import { useTranslation } from 'react-i18next';

import { LoadingAnimation } from 'shared/components';
import { BaseLayout } from 'shared/components/baseLayout';

export const Redirect = () => {
    const { t } = useTranslation();

    setTimeout(function () {
        window.open('about:blank', '_self');
        window.close();
    }, 7000);

    return (
        <BaseLayout showNavBar={false}>
            <LoadingAnimation text={t('authenticationComplete')} />;
        </BaseLayout>
    );
};
