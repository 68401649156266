import { Flex, Text, useMantineTheme } from '@mantine/core';
import { ReactNode } from 'react';

import { IconDescription } from '../IconDescription';

export type InputLabelProps = {
    label?: ReactNode;
    optional?: string;
    iconDescription?: string;
};

export const InputLabel = ({ label, optional, iconDescription }: InputLabelProps) => {
    const theme = useMantineTheme();

    return (
        <Flex gap={3} align="center">
            {label && (
                <Text size="sm" color={theme.colors.gray[9]} weight={500}>
                    {label}
                </Text>
            )}
            {optional && (
                <Text color={theme.colors.gray[6]} weight={400}>
                    ({optional})
                </Text>
            )}
            {iconDescription && <IconDescription description={iconDescription} />}
        </Flex>
    );
};
