import { Tooltip, useMantineTheme } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';

type Props = {
    description: string;
};

export const IconDescription = ({ description }: Props) => {
    const theme = useMantineTheme();
    return (
        <Tooltip label={description} position="right">
            <IconInfoCircle size={18} color={theme.colors.gray[5]} style={{ cursor: 'pointer' }} />
        </Tooltip>
    );
};
