import { OidcProvider, OidcConfiguration } from '@axa-fr/react-oidc';
import { ReactNode } from 'react';

import { AuthenticatingError, CallbackSuccess, Authenticating, Loading, SessionLost } from './components';
import { UserIntlProvider } from './UserIntlProvider';

type Props = {
    configuration: OidcConfiguration;
    children: ReactNode;
    autoLogin?: boolean;
};

export const AuthProvider = ({ configuration, children }: Props) => {
    return (
        <OidcProvider
            callbackSuccessComponent={CallbackSuccess}
            authenticatingComponent={Authenticating}
            loadingComponent={Loading}
            authenticatingErrorComponent={AuthenticatingError}
            sessionLostComponent={SessionLost}
            configuration={configuration}
        >
            <UserIntlProvider>{children}</UserIntlProvider>
        </OidcProvider>
    );
};
