import { Loader, Overlay, Box, Stack, Text } from '@mantine/core';
import { ReactNode } from 'react';

import { useStyles } from './LoadingAnimation.styles';

type LoadingAnimationProps = {
    children?: ReactNode;
    text?: string;
    showLoadingAnimation?: boolean;
};

export const LoadingAnimation = ({ children, text, showLoadingAnimation = true }: LoadingAnimationProps) => {
    const { classes, theme } = useStyles();

    if (showLoadingAnimation) {
        return (
            <Box className={classes.root}>
                <Stack align="center">
                    <Loader style={{ zIndex: 201 }} />
                    {text && <Text color="Black">{text}</Text>}
                </Stack>

                <Overlay opacity={0.75} color={theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.white} />
            </Box>
        );
    }
    return <>{children}</>;
};
