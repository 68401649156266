import {
    Stack,
    Space,
    Box,
    Text,
    Title,
    Anchor,
    Accordion,
    useMantineTheme,
    Spoiler,
    Modal,
    Group,
    Button,
    TextInput,
    Checkbox,
    Stepper,
    rem
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { IconShieldCheck, IconCircleCheck, IconUsersGroup, IconUser } from '@tabler/icons-react';
import { useState } from 'react';
// import { useNavigate } from "react-router-dom";

import { BaseLayout } from 'shared/components/baseLayout';

export function ClientInstaller() {
    const [active, setActive] = useState(0);
    // const [value, setValue] = useState<string[]>([]);
    const theme = useMantineTheme();
    const getColor = (color: string) => theme.colors[color][theme.colorScheme === 'dark' ? 5 : 7];
    const [opened, { open, close }] = useDisclosure(false);

    const userForm = useForm({
        initialValues: {
            tandc: false,
            dataprivacy: false
        },
        validate: {
            tandc: (value) => (!value ? 'You have to accept the terms and conditions' : null),
            dataprivacy: (value) => (!value ? 'You have to accept the terms and conditions' : null)
        }
    });

    const enterpriseForm = useForm({
        initialValues: {
            name: '',
            companyname: '',
            termsOfService: false,
            tandc: false,
            dataprivacy: false
        },

        validate: (values) => {
            if (active === 0) {
                return {
                    name: values.name.trim().length < 2 ? 'Name must include at least 2 characters' : null,
                    companyname:
                        values.companyname.trim().length < 2 ? 'Company name must include at least 2 characters' : null
                };
            }

            if (active === 1) {
                return {
                    tandc: !values.tandc ? 'You have to accept the terms and conditions' : null,
                    dataprivacy: !values.dataprivacy ? 'You have to accept the terms and conditions' : null
                };
            }

            return {};
        }
    });

    const nextStep = () =>
        setActive((current) => {
            if (enterpriseForm.validate().hasErrors) {
                return current;
            }
            return current < 3 ? current + 1 : current;
        });

    const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));

    const finalStep = () =>
        setActive((current) => {
            if (enterpriseForm.validate().hasErrors) {
                return current;
            }
            DownloadInstaller();
            return current;
        });

    function DownloadInstaller() {
        window.location.href =
            'https://download-software-installer.doka.com/installer_client/Installer.Client.Packaging.appinstaller';
    }

    return (
        <BaseLayout showNavBar={false}>
            <Stack align="center">
                <Accordion variant="contained" chevronPosition="right" radius="md" defaultValue="single">
                    <Accordion.Item value="single">
                        <Accordion.Control icon={<IconUser size={rem(20)} color={getColor('blue')} />}>
                            Single User Installation
                        </Accordion.Control>
                        <Accordion.Panel>
                            <Box w={900}>
                                <form onSubmit={userForm.onSubmit(() => DownloadInstaller())}>
                                    <Title>Doka Software Installer</Title>
                                    This Installer enables selective download and installation of the DFDS programms.
                                    <Space h="md" />
                                    <Space h="sm" />
                                    <Checkbox
                                        size="md"
                                        required
                                        label={
                                            <>
                                                I accept{' '}
                                                <Anchor
                                                    href="https://www.doka.com/web/media/files/solutions/gtc-doka-planning-software.pdf"
                                                    target="_blank"
                                                >
                                                    terms and conditions
                                                </Anchor>
                                                *
                                            </>
                                        }
                                        {...userForm.getInputProps('tandc', { type: 'checkbox' })}
                                    />
                                    <Space h="xs" />
                                    <Checkbox
                                        size="md"
                                        required
                                        label={
                                            <>
                                                I accept{' '}
                                                <Anchor
                                                    href="https://www.doka.com/web/media/files/solutions/Privacy-Statement-Doka-Designsoftware.pdf"
                                                    target="_blank"
                                                >
                                                    data privacy statement
                                                </Anchor>
                                                *
                                            </>
                                        }
                                        {...userForm.getInputProps('dataprivacy', { type: 'checkbox' })}
                                    />
                                    <Space h="xl" />
                                    <Button
                                        type="submit"
                                        // component="a"
                                        // target="_blank"
                                        // rel="noopener noreferrer"
                                        // href="https://download-software-installer.doka.com/installer_client/Installer.Client.Packaging.appinstaller"
                                    >
                                        Download
                                    </Button>
                                    <Space h="xl" />
                                </form>
                            </Box>
                        </Accordion.Panel>
                    </Accordion.Item>

                    <Accordion.Item value="multi">
                        <Accordion.Control icon={<IconUsersGroup size={rem(20)} color={getColor('blue')} />}>
                            Multi User Installation for enterprises and schools only
                        </Accordion.Control>
                        <Accordion.Panel>
                            <Box w={900}>
                                <Spoiler maxHeight={120} showLabel="Show more" hideLabel="Hide">
                                    <Title>Doka Enterprise Software Installer</Title>
                                    This Installer enables selective download and installation of the DFDS programms.
                                    This Installer enables selective download and installation of the DFDS programms.
                                    This Installer enables selective download and installation of the DFDS programms.
                                    This Installer enables selective download and installation of the DFDS programms.
                                    This Installer enables selective download and installation of the DFDS programms.
                                    This Installer enables selective download and installation of the DFDS programms.
                                    This Installer enables selective download and installation of the DFDS programms.
                                    <Text>Paper is the most basic ui component</Text>
                                    <Text>
                                        Use it to create cards, dropdowns, modals and other components that require
                                        background with shadow
                                    </Text>
                                    <Space h="md" />
                                    <Space h="xl" />
                                    <Button onClick={open}>Request permission</Button>
                                    <Space h="xl" />
                                </Spoiler>
                            </Box>
                        </Accordion.Panel>
                    </Accordion.Item>
                </Accordion>
            </Stack>
            <Modal
                opened={opened}
                onClose={close}
                title="Request enterprise installer"
                centered
                overlayProps={{
                    color: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2],
                    opacity: 0.55,
                    blur: 3
                }}
            >
                <Stepper
                    active={active}
                    onStepClick={setActive}
                    completedIcon={<IconCircleCheck />}
                    breakpoint="sm"
                    allowNextStepsSelect={false}
                >
                    <Stepper.Step label="Step 1" description="Personal information">
                        <TextInput
                            label="Name"
                            placeholder="Name"
                            {...enterpriseForm.getInputProps('name')}
                            withAsterisk
                        />
                        <TextInput
                            label="Name of Company / school"
                            placeholder="Name of Company / school"
                            {...enterpriseForm.getInputProps('companyname')}
                            withAsterisk
                        />
                    </Stepper.Step>
                    <Stepper.Step icon={<IconShieldCheck size="1.1rem" />} label="Step 2" description="Get full access">
                        <Checkbox
                            size="md"
                            required
                            label={
                                <>
                                    I accept{' '}
                                    <Anchor
                                        href="https://www.doka.com/web/media/files/solutions/gtc-doka-planning-software.pdf"
                                        target="_blank"
                                    >
                                        terms and conditions
                                    </Anchor>
                                </>
                            }
                            {...enterpriseForm.getInputProps('tandc')}
                        />
                        <Space h="xs" />
                        <Checkbox
                            size="md"
                            required
                            label={
                                <>
                                    I accept{' '}
                                    <Anchor
                                        href="https://www.doka.com/web/media/files/solutions/Privacy-Statement-Doka-Designsoftware.pdf"
                                        target="_blank"
                                    >
                                        data privacy statement
                                    </Anchor>
                                </>
                            }
                            {...enterpriseForm.getInputProps('dataprivacy')}
                        />
                    </Stepper.Step>
                </Stepper>
                <Group position="right" mt="xl">
                    {active !== 0 && (
                        <Button variant="default" onClick={prevStep}>
                            Back
                        </Button>
                    )}
                    {active !== 1 && <Button onClick={nextStep}>Next step</Button>}
                    {active === 1 && <Button onClick={finalStep}>Download</Button>}
                </Group>
            </Modal>
        </BaseLayout>
    );
}
