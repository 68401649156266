import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { ErrorInfo, ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useLocation } from 'react-router-dom';

import { ErrorBoundaryFallback } from './';

type Props = {
    children: ReactNode;
    reactPlugin: ReactPlugin;
};

export const DefaultErrorBoundary = ({ children, reactPlugin }: Props) => {
    const location = useLocation();

    const handleError = (error: Error, errorInfo: ErrorInfo) => {
        reactPlugin &&
            reactPlugin?.trackException({
                error: error,
                exception: error,
                severityLevel: SeverityLevel.Error,
                properties: errorInfo
            });
    };

    return (
        <ErrorBoundary key={location.pathname} onError={handleError} FallbackComponent={ErrorBoundaryFallback}>
            {children}
        </ErrorBoundary>
    );
};
