import {
    TextInput,
    TextInputProps,
    Textarea,
    TextareaProps,
    Group,
    Text,
    Tooltip,
    UnstyledButton,
    useMantineTheme,
    MultiSelect,
    MultiSelectProps,
    Stack
} from '@mantine/core';
import { FormErrors, UseFormReturnType } from '@mantine/form';
import { IconInfoCircle } from '@tabler/icons-react';
import React, { forwardRef, RefAttributes } from 'react';

import { useStyles } from './ExtendedInputControls.styles';

export interface IIconDescription {
    label?: React.ReactNode;
    iconDescription?: string | undefined;
    optional?: string;
}

export const Label = ({ label, iconDescription, optional }: IIconDescription) => {
    const theme = useMantineTheme();

    return (
        <Group spacing={5}>
            <Text>{label}</Text>
            {!!optional && <Text style={{ fontWeight: 'lighter' }}>({optional})</Text>}
            {iconDescription && (
                <Tooltip label={iconDescription} position="right">
                    <UnstyledButton>
                        <IconInfoCircle
                            color={theme.colors.gray[5]}
                            size="18px"
                            style={{ marginBottom: '1px', verticalAlign: 'middle' }}
                        />
                    </UnstyledButton>
                </Tooltip>
            )}
        </Group>
    );
};

export const GetDefaultExtendedInputValues = ({
    warnings,
    disabled,
    propertyName,
    form
}: {
    warnings?: FormErrors;
    disabled: boolean | undefined;
    propertyName: string;
    form: UseFormReturnType<unknown, never>;
}) => {
    const { classes, cx } = useStyles();
    const warningText = !disabled && warnings && warnings[propertyName];

    return {
        warning: warningText && !form.errors[propertyName] && (
            <Text className={classes.warningLabel}>{warningText}</Text>
        ),
        warningClassName: cx({ [classes.warning]: !!warningText && !form.errors[propertyName] })
    };
};

export interface IMantineFormControl {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    form: UseFormReturnType<any, any>;
    propertyPath: string;
}

export interface IWarningProps {
    warnings?: FormErrors;
}

export interface ExtendedTextInputProps
    extends IMantineFormControl,
        IWarningProps,
        IIconDescription,
        Omit<TextInputProps, 'form'>,
        RefAttributes<HTMLInputElement> {}

export const ExtendedTextInput = forwardRef<HTMLInputElement, ExtendedTextInputProps>(
    (
        {
            form,
            propertyPath: propertyName,
            label,
            warnings,
            iconDescription,
            style,
            disabled,
            ...others
        }: ExtendedTextInputProps,
        ref
    ) => {
        const { warning, warningClassName } = GetDefaultExtendedInputValues({ warnings, disabled, form, propertyName });
        // TODO: Change to better solution after moving to react-hook-form
        const props = form.getInputProps(propertyName);

        if (props.value === null) {
            props.value = '';
        }

        return (
            <Stack spacing={0} style={style}>
                <TextInput
                    disabled={disabled}
                    {...props}
                    ref={ref}
                    className={warningClassName}
                    {...others}
                    label={label && <Label label={label} iconDescription={iconDescription} />}
                />
                {warning}
            </Stack>
        );
    }
);

ExtendedTextInput.displayName = 'ExtendedTextInput';

export interface ExtendedTextAreaProps
    extends IMantineFormControl,
        IWarningProps,
        IIconDescription,
        Omit<TextareaProps, 'form'>,
        RefAttributes<HTMLTextAreaElement> {}

export const ExtendedTextarea = forwardRef<HTMLTextAreaElement, ExtendedTextAreaProps>(
    (
        {
            form,
            propertyPath: propertyName,
            label,
            iconDescription,
            warnings,
            optional,
            disabled,
            ...others
        }: ExtendedTextAreaProps,
        ref
    ) => {
        const { warning, warningClassName } = GetDefaultExtendedInputValues({ warnings, disabled, form, propertyName });

        // TODO: Change to better solution after moving to react-hook-form
        const props = form.getInputProps(propertyName);

        if (props.value === null) {
            props.value = '';
        }

        return (
            <Stack spacing={0}>
                <Textarea
                    disabled={disabled}
                    {...props}
                    ref={ref}
                    className={warningClassName}
                    {...others}
                    label={label && <Label label={label} iconDescription={iconDescription} optional={optional} />}
                />
                {warning}
            </Stack>
        );
    }
);

ExtendedTextarea.displayName = 'ExtendedTextarea';

export interface ExtendedMultiSelectProps
    extends IMantineFormControl,
        IWarningProps,
        IIconDescription,
        Omit<MultiSelectProps, 'form'>,
        RefAttributes<HTMLInputElement> {}

export const ExtendedMultiSelect = forwardRef<HTMLInputElement, ExtendedMultiSelectProps>(
    (
        {
            form,
            propertyPath: propertyName,
            label,
            warnings,
            iconDescription,
            optional,
            disabled,
            ...others
        }: ExtendedMultiSelectProps,
        ref
    ) => {
        const { warning, warningClassName } = GetDefaultExtendedInputValues({ warnings, disabled, form, propertyName });

        // TODO: Change to better solution after moving to react-hook-form
        const props = form.getInputProps(propertyName);

        if (props.value === null) {
            props.value = '';
        }

        return (
            <Stack spacing={0}>
                <MultiSelect
                    disabled={disabled}
                    {...props}
                    ref={ref}
                    {...others}
                    className={warningClassName}
                    label={<Label label={label} iconDescription={iconDescription} optional={optional} />}
                />
                {warning}
            </Stack>
        );
    }
);

ExtendedMultiSelect.displayName = 'ExtendedMultiSelect';
