import { useOidc } from '@axa-fr/react-oidc';
import { Button } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { ErrorView } from 'shared/components/ErrorView';

export const SessionLost = () => {
    const { t } = useTranslation();
    const { login } = useOidc();
    return (
        <>
            <ErrorView title={t('sessionTimedOut')} message={t('reauthenticate')}>
                <Button onClick={() => login('/')}>{t('login')}</Button>
            </ErrorView>
        </>
    );
};
